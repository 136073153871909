import moment from "moment";
import ApiService from "../services/ApiService";
interface Permission {
  id: number;
  module_name: string;
  is_active: boolean;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  show_cost: boolean;
  user_role: string;
  created_by: string;
}

const PERMISSION_MODULE_NAME = {
  MATERIAL: "MATERIAL",
  MATERIAL_STOCK: "MATERIAL_STOCK",
  PARTY: "PARTY",
  DESIGN: "DESIGN",
  DESIGN_STOCK: "DESIGN_STOCK",
  PRODUCTION: "PRODUCTION",
  PRODUCTION_STOCK: "PRODUCTION_STOCK",
  PEELING: "PEELING",
  FUSING: "FUSING",
  CUTTING: "CUTTING",
  UNUSED_STOCK: "UNUSED_STOCK",
  DELIVERY_CHALLAN: "DELIVERY_CHALLAN",
}
const PermissionObj = {}
Object.keys(PERMISSION_MODULE_NAME).forEach(key => {
  PermissionObj[PERMISSION_MODULE_NAME[key]] = {}
});
const PERMISSION_MODULE_NAME_LIST = Object.keys(PERMISSION_MODULE_NAME).map(key => ({ label: key, id: PERMISSION_MODULE_NAME[key] }))
const CancelToken = ApiService.getInstance()?.CancelToken;
let cancelRequest;
const Permissions: Array<Permission> = [];

const deletePermission = function (item: Permission) {
  return ApiService.delete(`permission/${item.id}/`);
};

const addPermission = function (item, id) {
  delete item.created_by
  if (id) {
    return ApiService.put(`permission/${id}/`, item);
  } else {
    return ApiService.post("permission/", item);
  }
};

const exportPermissionData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/permission/?design=" + ids,
      "permission-data"
    ).then((data) => res(data));
  });
};
const exportPDFPermissions = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/permission/?design=" + ids).then((data) =>
      res(data)
    );
  });
};
const permissionObjClone = function () {
  return {
    id: 0,
    module_name: "",
    is_active: false,
    create: false,
    read: false,
    update: false,
    delete: false,
    show_cost: false,
    user_role: "",
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: Permission = permissionObjClone();
  newItem.id = item.id;
  newItem.module_name = item.module_name;
  newItem.is_active = item.is_active;
  newItem.create = item.create;
  newItem.read = item.read;
  newItem.update = item.update;
  newItem.delete = item.delete;
  newItem.show_cost = item.show_cost;
  newItem.user_role = item.user_role;
  newItem.created_by = item.created_by;
  return newItem;
};
const getPermissionById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`permission/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getPermissions = function (
  pageNumber = 1,
  searchData,
  status = "",
  user_role = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      user_role: user_role,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken?.source();
    ApiService.getWithoutSlash(`permission/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfacePermission = jsonToInterface;
const getEmptyObjOfPermission = permissionObjClone;
export {
  Permission,
  getPermissions,
  deletePermission,
  addPermission,
  getPermissionById,
  jsonToInterfacePermission,
  permissionObjClone,
  exportPermissionData,
  exportPDFPermissions,
  getEmptyObjOfPermission,
  PERMISSION_MODULE_NAME,
  PERMISSION_MODULE_NAME_LIST,
  PermissionObj
};

export default Permissions;
