import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/material/material-listing",
    component: () => import("@/layout/Layout.vue"),
    children: [
      // MIS Route Sites
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/users/users-listing",
        name: "apps-users-listing",
        component: () => import("@/views/apps/users/Listing.vue"),
      },
      {
        path: "/users/add",
        name: "apps-add-users",
        component: () => import("@/views/apps/users/Form.vue"),
      },
      {
        path: "/users/details/:id",
        name: "apps-edit-users",
        component: () => import("@/views/apps/users/Form.vue"),
      },
      {
        path: "/users/change-pwd/:id",
        name: "apps-ChangePWD-users",
        component: () => import("@/views/apps/users/ChangePWD.vue"),
      },
      {
        path: "/material/material-listing",
        name: "apps-material-listing",
        component: () => import("@/views/apps/material/Listing.vue"),
      },
      {
        path: "/material/add",
        name: "apps-add-material",
        component: () => import("@/views/apps/material/Form.vue"),
      },
      {
        path: "/material/details/:id",
        name: "apps-edit-material",
        component: () => import("@/views/apps/material/Form.vue"),
      },
      {
        path: "/material/details/:id/stock",
        name: "apps-stock-material",
        component: () => import("@/views/apps/material/stock/Listing.vue"),
      },
      {
        path: "/material/details/:id/stock/add",
        name: "apps-stock-material-add",
        component: () => import("@/views/apps/material/stock/Form.vue"),
      },
      {
        path: "/material/details/:id/stock/:stockId",
        name: "apps-stock-material-details",
        component: () => import("@/views/apps/material/stock/Form.vue"),
      },

      // Party
      {
        path: "/party/party-listing",
        name: "apps-party-listing",
        component: () => import("@/views/apps/party/Listing.vue"),
      },
      {
        path: "/party/add",
        name: "apps-add-party",
        component: () => import("@/views/apps/party/Form.vue"),
      },
      {
        path: "/party/details/:id",
        name: "apps-edit-party",
        component: () => import("@/views/apps/party/Form.vue"),
      },

      // Season
      {
        path: "/season/season-listing",
        name: "apps-season-listing",
        component: () => import("@/views/apps/season/Listing.vue"),
      },
      {
        path: "/season/add",
        name: "apps-add-season",
        component: () => import("@/views/apps/season/Form.vue"),
      },
      {
        path: "/season/details/:id",
        name: "apps-edit-season",
        component: () => import("@/views/apps/season/Form.vue"),
      },
      {
        path: "/season/:id/production",
        name: "apps-production-season",
        component: () => import("@/views/apps/production/Listing.vue"),
      },

      // delivery-challan
      {
        path: "/delivery-challan/delivery-challan-listing",
        name: "apps-delivery-challan-listing",
        component: () => import("@/views/apps/delivery-challan/Listing.vue"),
      },
      {
        path: "/delivery-challan/add",
        name: "apps-add-delivery-challan",
        component: () => import("@/views/apps/delivery-challan/Form.vue"),
      },
      {
        path: "/delivery-challan/details/:id",
        name: "apps-edit-delivery-challan",
        component: () => import("@/views/apps/delivery-challan/Form.vue"),
      },
      // unused-stock
      {
        path: "/unused-stock/unused-stock-listing",
        name: "apps-unused-stock-listing",
        component: () => import("@/views/apps/unused-stock/Listing.vue"),
      },
      {
        path: "/unused-stock/add",
        name: "apps-add-unused-stock",
        component: () => import("@/views/apps/unused-stock/Form.vue"),
      },
      {
        path: "/unused-stock/details/:id",
        name: "apps-edit-unused-stock",
        component: () => import("@/views/apps/unused-stock/Form.vue"),
      },
      // Design
      {
        path: "/design/design-listing",
        name: "apps-design-listing",
        component: () => import("@/views/apps/design/Listing.vue"),
      },
      {
        path: "/design/add",
        name: "apps-add-design",
        component: () => import("@/views/apps/design/Form.vue"),
      },
      {
        path: "/design/details/:id",
        name: "apps-edit-design",
        component: () => import("@/views/apps/design/Form.vue"),
        props: (route) => ({ isCloned: route.query.isCloned === "true" }),
      },
      {
        path: "/design/details/:id/stock",
        name: "apps-stock-design",
        component: () => import("@/views/apps/design/stock/Listing.vue"),
      },
      {
        path: "/design/details/:id/stock/add",
        name: "apps-stock-design-add",
        component: () => import("@/views/apps/design/stock/Form.vue"),
      },
      {
        path: "/design/details/:id/stock/:stockId",
        name: "apps-stock-design-details",
        component: () => import("@/views/apps/design/stock/Form.vue"),
      },
      {
        path: "/design/details/:id/material",
        name: "apps-material-design",
        component: () => import("@/views/apps/design/material/Listing.vue"),
      },
      {
        path: "/design/details/:id/material/add",
        name: "apps-material-design-add",
        component: () => import("@/views/apps/design/material/Form.vue"),
      },
      {
        path: "/design/details/:id/material/:materialId",
        name: "apps-material-design-details",
        component: () => import("@/views/apps/design/material/Form.vue"),
      },

      // User Role
      {
        path: "/role/role-listing",
        name: "apps-role-listing",
        component: () => import("@/views/apps/role/Listing.vue"),
      },
      {
        path: "/role/add",
        name: "apps-add-role",
        component: () => import("@/views/apps/role/Form.vue"),
      },
      {
        path: "/role/details/:id",
        name: "apps-edit-role",
        component: () => import("@/views/apps/role/Form.vue"),
      },
      {
        path: "/role/details/:id/permission",
        name: "apps-permission-role",
        component: () => import("@/views/apps/role/permission/Listing.vue"),
      },
      {
        path: "/role/details/:id/permission/add",
        name: "apps-permission-role-add",
        component: () => import("@/views/apps/role/permission/Form.vue"),
      },
      {
        path: "/role/details/:id/permission/:permissionId",
        name: "apps-permission-role-details",
        component: () => import("@/views/apps/role/permission/Form.vue"),
      },
      // Production
      {
        path: "/production/production-listing",
        name: "apps-production-listing",
        component: () => import("@/views/apps/production/Listing.vue"),
      },
      {
        path: "/production/add",
        name: "apps-add-production",
        component: () => import("@/views/apps/production/Form.vue"),
      },
      {
        path: "/production/details/:id",
        name: "apps-edit-production",
        component: () => import("@/views/apps/production/Form.vue"),
      },
      {
        path: "/production/details/:id/stock",
        name: "apps-stock-production",
        component: () => import("@/views/apps/production/stock/Listing.vue"),
      },
      {
        path: "/production/details/:id/stock/add",
        name: "apps-stock-production-add",
        component: () => import("@/views/apps/production/stock/Form.vue"),
      },
      {
        path: "/production/details/:id/stock/:stockId",
        name: "apps-stock-production-details",
        component: () => import("@/views/apps/production/stock/Form.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId",
        name: "apps-design-production-landing",
        component: () => import("@/views/apps/production/Landing.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId/material-production/:designMaterialId/cutting",
        name: "apps-cutting-production",
        component: () => import("@/views/apps/production/cutting/Listing.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId/material-production/:designMaterialId/cutting/add",
        name: "apps-cutting-production-add",
        component: () => import("@/views/apps/production/cutting/Form.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId/material-production/:designMaterialId/cutting/:cuttingId",
        name: "apps-cutting-production-details",
        component: () => import("@/views/apps/production/cutting/Form.vue"),
      },

      /**
       * Peeling
       */
      {
        path: "/production/details/:id/production-design/:designId/material-production/:designMaterialId/peeling",
        name: "apps-peeling-production",
        component: () => import("@/views/apps/production/peeling/Listing.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId/material-production/:designMaterialId/peeling/add",
        name: "apps-peeling-production-add",
        component: () => import("@/views/apps/production/peeling/Form.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId/material-production/:designMaterialId/peeling/:peelingId",
        name: "apps-peeling-production-details",
        component: () => import("@/views/apps/production/peeling/Form.vue"),
      },
      /**
       * Fusing
       */
      {
        path: "/production/details/:id/production-design/:designId/fusing",
        name: "apps-fusing-production",
        component: () => import("@/views/apps/production/fusing/Listing.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId/fusing/add",
        name: "apps-fusing-production-add",
        component: () => import("@/views/apps/production/fusing/Form.vue"),
      },
      {
        path: "/production/details/:id/production-design/:designId/fusing/:fusingId",
        name: "apps-fusing-production-details",
        component: () => import("@/views/apps/production/fusing/Form.vue"),
      },
      {
        path: "/notification/notification-listing",
        name: "apps-notification-listing",
        component: () => import("@/views/apps/notification/Listing.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);
  store.dispatch(Actions.GET_PERMISSION);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
