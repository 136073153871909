import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.interceptors.request.use(
      function (response) {
        return response;
      },
      function (error) {
        console.error(error);
        if (error.response?.status == 403) {
          window.localStorage.clear();
          // window.location.reload();
          // window.location.href = "/#/sign-in";
        }
        return Promise.reject(error.response);
      }
    );
    ApiService.vueInstance.axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.error(error);
        switch (error.response?.status) {
          case 403:
          case 401:
            window.localStorage.clear();
            // window.location.reload();
            window.location.href = "/#/sign-in";
            break;
          case 413:
            error.response.data = ["ERROR: File should be max 500 KB"];
            break;
          default:
            break;
        }
        console.error(error);
        return Promise.reject(error.response);
      }
    );
  }

  public static getInstance(): any {
    return ApiService.vueInstance?.axios;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `JWT ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static clearHeader(): void {
    delete ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ];
    delete ApiService.vueInstance.axios.defaults.headers.common["Accept"];
  }
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/`);
  }

  public static getWithoutSlash(
    resource: string,
    cancelToken: any
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`, {
      cancelToken: cancelToken?.token,
    });
  }

  public static getWithoutSlashNoCancel(
    resource: string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`, {});
  }
  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: any): Promise<AxiosResponse> {
    if (store.getters.currentUser.role == "ADMIN") {
      return ApiService.vueInstance.axios.put(`${resource}`, params);
    } else {
      return new Promise((res, rej) => {
        Swal.fire({
          title: `<div><span>Are You sure you want to</span> <span class='text-success'>Edit</span> <span>?</span></div>`,
          html: `Kindly Add Your Note Here!`,
          input: "textarea",
          inputPlaceholder: "Enter your text here...",
          inputAttributes: {
            autocapitalize: "off",
          },
          customClass: {
            content: "m-0",
            container: "m-0",
            htmlContainer: "m-0",
            title: "d-block",
            confirmButton: `btn btn-success m-0 me-2`,
            cancelButton: `btn btn-default m-0 ms-2`,
          },
          showCancelButton: true,
          confirmButtonText: `Confirm`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            if (params instanceof FormData) {
              params.append("confirm_request_remark", result.value);
            } else {
              params.confirm_request_remark = result.value;
            }
            // if(typeof params)
            res(ApiService.vueInstance.axios.put(`${resource}`, params));
          } else {
            rej(["Changes not saved!!!"]);
          }
        });
      });
    }
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    if (store.getters.currentUser.role == "ADMIN") {
      return ApiService.vueInstance.axios.delete(resource);
    } else {
      return new Promise((res, rej) => {
        Swal.fire({
          title: `<div><span>Are You sure you want to</span> <span class='text-danger'>Delete</span> <span>?</span></div>`,
          html: `Kindly Add Your Note Here!`,
          input: "textarea",
          inputPlaceholder: "Enter your text here...",
          inputAttributes: {
            autocapitalize: "off",
          },
          customClass: {
            content: "m-0",
            container: "m-0",
            htmlContainer: "m-0",
            title: "d-block",
            confirmButton: `btn btn-danger m-0 me-2`,
            cancelButton: `btn btn-default m-0 ms-2`,
          },
          showCancelButton: true,
          confirmButtonText: `Confirm`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            res(
              ApiService.vueInstance.axios.delete(
                `${resource}?confirm_request_remark=${result.value}`
              )
            );
          } else {
            rej(["Changes not saved!!!"]);
          }
        });
      });
    }
  }
  public static pdfFile(resource: string): Promise<any> {
    return new Promise((res, rej) => {
      ApiService.vueInstance.axios.get(resource).then(function (response) {
        const printWindow = window.open("", "", "height=400,width=800");
        printWindow?.document.write(response.data);
        printWindow?.document.close();
        res(true);
      });
    });
  }

  public static downloadFile(resource: string): Promise<any> {
    return new Promise((res, rej) => {
      ApiService.vueInstance.axios
        .get(resource, { responseType: "blob" })
        .then(function (response) {
          const fileName =
            response.headers["content-disposition"].split("filename=")[1];
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }),
              fileName
            );
            res(true);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              response.headers["content-disposition"].split("filename=")[1]
            );
            document.body.appendChild(link);
            link.click();
            res(true);
          }
        });
    });
  }
  public static csvFile(resource: string, fileName: string): Promise<any> {
    return new Promise((res, rej) => {
      ApiService.vueInstance.axios
        .get(resource, { responseType: "blob" })
        .then(function (response) {
          if (!fileName) {
            fileName =
              response.headers["content-disposition"]?.split("filename=")[1];
          }
          const csv = response.data;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([csv], {
                type: "text/csv;charset=utf-8;",
              }),
              fileName
            );
            res(true);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([csv], {
                type: "text/csv;charset=utf-8;",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            console.log("fileName", fileName);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            res(true);
          }
        });
    });
  }
}

export default ApiService;
